import store from "@/store";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { ErrorResponse } from "@/services/axios/error";
import { IRenewablePerformanceData } from "@/models/Site";
import { covertDataChartScope2 } from "@/utils/helpers/convertDataChart";
import { KEYS_MAPPING } from "@ems/constants";
import { SiteAPI } from "@/services/site";

const name = "RenewableEnergyPerformanceModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class RenewableEnergyPerformanceModule extends VuexModule {
  private dataRenewablePerformance = {} as IRenewablePerformanceData;
  private errorRenewablePerformance = {} as ErrorResponse;
  private loadingRenewablePerformance = false;

  private dataScope_2 = [] as any[];
  private dataRenewables_2 = [] as any[];
  private dataTarget_2 = [] as any[];
  private dataMarker = [] as any[];

  get dataRenewablePerformanceGetter() {
    return {
      dataRenewablePerformance: this.dataRenewablePerformance,
      dataScope_2: this.dataScope_2,
      dataRenewables_2: this.dataRenewables_2,
      dataTarget_2: this.dataTarget_2,
      dataMarker: this.dataMarker,
    };
  }

  get loadingRenewablePerformanceGetter() {
    return this.loadingRenewablePerformance;
  }

  get errorRenewablePerformanceGetter() {
    return this.errorRenewablePerformance;
  }

  @Mutation
  setLoadingRenewablePerformance(isLoading = false as boolean) {
    this.loadingRenewablePerformance = isLoading;
  }

  @Mutation
  getRenewablePerformanceError(error: ErrorResponse) {
    this.loadingRenewablePerformance = false;
    this.errorRenewablePerformance = error;
  }

  @Mutation
  getRenewablePerformanceSuccess(data: IRenewablePerformanceData) {
    this.loadingRenewablePerformance = false;
    this.dataRenewablePerformance = data;
    this.dataTarget_2 = covertDataChartScope2(
      data.FacilityRenewablePerformanceData,
      KEYS_MAPPING.MONTH,
      KEYS_MAPPING.RENEWABLES_ENERGY_USAGE
    );
    this.dataScope_2 = covertDataChartScope2(
      data.FacilityRenewablePerformanceData,
      KEYS_MAPPING.MONTH,
      KEYS_MAPPING.SCOPE2_ENERGY_USAGE
    );
    this.dataTarget_2 = covertDataChartScope2(
      data.FacilityRenewablePerformanceData,
      KEYS_MAPPING.MONTH,
      KEYS_MAPPING.RENEWABLES_ENERGY_USAGE_PERCENTAGE
    );
    this.dataRenewables_2 = covertDataChartScope2(
      data.FacilityRenewablePerformanceData,
      KEYS_MAPPING.MONTH,
      KEYS_MAPPING.RENEWABLES_ENERGY_USAGE
    );
    this.dataMarker = covertDataChartScope2(
      data.FacilityRenewablePerformanceData,
      KEYS_MAPPING.MONTH,
      KEYS_MAPPING.RENEWABLES_TARGET_PERCENTAGE
    );
  }

  @Action({ rawError: true })
  async getRenewablePerformanceAction(payload = {} as any) {
    this.setLoadingRenewablePerformance(true);
    const servicesSiteAPI = new SiteAPI();
    const { error, data } = await servicesSiteAPI.getSitesRenewablePerformance(
      payload.FacilityId,
      payload.year
    );
    if (error) {
      this.getRenewablePerformanceError(error);
    } else {
      this.getRenewablePerformanceSuccess(data);
    }
  }
}

export default getModule(RenewableEnergyPerformanceModule);
