import store from "@/store";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { ErrorResponse } from "@/services/axios/error";
import { IOperationsAlarmData } from "@/models/Site";
import { SiteAPI } from "@/services/site";

const name = "OperationsMaintenanceAlarmsModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class OperationsMaintenanceAlarmsModule extends VuexModule {
  private dataOperationsAlarm = [] as IOperationsAlarmData[];
  private errorOperationsAlarm = {} as ErrorResponse;
  private loadingOperationsAlarm = false;

  get dataOperationsAlarmGetter() {
    return this.dataOperationsAlarm;
  }

  @Mutation
  setLoadingOperationsAlarm(isLoading = false as boolean) {
    this.loadingOperationsAlarm = isLoading;
  }
  @Mutation
  getOperationsAlarmError(error: ErrorResponse) {
    this.loadingOperationsAlarm = false;
    this.errorOperationsAlarm = error;
  }
  @Mutation
  getOperationsAlarmSuccess(data: IOperationsAlarmData[]) {
    this.loadingOperationsAlarm = false;
    this.dataOperationsAlarm = data;
  }
  @Action({ rawError: true })
  async getDataOperationsAlarmAction(FacilityId: string) {
    this.setLoadingOperationsAlarm(true);
    const servicesSiteAPI = new SiteAPI();
    const { error, data } = await servicesSiteAPI.getSitesOperationsAlarm(
      FacilityId
    );
    if (error) {
      this.getOperationsAlarmError(error);
    } else {
      this.getOperationsAlarmSuccess(data);
    }
  }
}
export default getModule(OperationsMaintenanceAlarmsModule);
