import store from "@/store";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";

import { ErrorResponse } from "@/services/axios/error";
import {
  IOverviewGeneration,
  IOverviewConsumption,
  IOverviewEnergyStorage,
} from "@/models/Site";
import { SiteAPI } from "@/services/site";

const name = "OverviewModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class OverviewModule extends VuexModule {
  private errorOverviewGeneration = {} as ErrorResponse;
  private loadingOverviewGeneration = false;
  private dataOverviewGeneration = {} as IOverviewGeneration;

  private errorOverviewConsumption = {} as ErrorResponse;
  private loadingOverviewConsumption = false;
  private dataOverviewConsumption = {} as IOverviewConsumption;

  private errorOverviewEnergyStorage = {} as ErrorResponse;
  private loadingOverviewEnergyStorage = false;
  private dataOverviewEnergyStorage = {} as IOverviewEnergyStorage;

  get dataOverviewGenerationGetter() {
    return this.dataOverviewGeneration;
  }
  get loadingOverviewGenerationGetter() {
    return this.loadingOverviewGeneration;
  }
  get errorOverviewGenerationGetter() {
    return this.errorOverviewGeneration;
  }

  get dataOverviewConsumptionGetter() {
    return this.dataOverviewConsumption;
  }
  get loadingOverviewConsumptionGetter() {
    return this.loadingOverviewConsumption;
  }
  get errorOverviewConsumptionGetter() {
    return this.errorOverviewConsumption;
  }

  get dataOverviewEnergyStorageGetter() {
    return this.dataOverviewEnergyStorage;
  }
  get loadingOverviewEnergyStorageGetter() {
    return this.loadingOverviewEnergyStorage;
  }
  get errorOverviewEnergyStorageGetter() {
    return this.errorOverviewEnergyStorage;
  }

  @Mutation
  setLoadingOverviewGeneration(isLoading = false as boolean) {
    this.loadingOverviewGeneration = isLoading;
  }
  @Mutation
  setOverviewGenerationError(error: ErrorResponse) {
    this.loadingOverviewGeneration = false;
    this.errorOverviewGeneration = error;
  }
  @Mutation
  setOverviewGenerationSuccess(data: IOverviewGeneration) {
    this.loadingOverviewGeneration = false;
    this.dataOverviewGeneration = data;
  }
  @Action({ rawError: true })
  async fetchOverviewGeneration(FacilityId: string) {
    this.setLoadingOverviewGeneration(true);
    const servicesSiteAPI = new SiteAPI();
    const { error, data } = await servicesSiteAPI.getOverviewGeneration(
      FacilityId
    );
    if (error) {
      this.setOverviewGenerationError(error);
    } else {
      this.setOverviewGenerationSuccess(data);
    }
  }

  @Mutation
  setLoadingOverviewConsumption(isLoading = false as boolean) {
    this.loadingOverviewConsumption = isLoading;
  }
  @Mutation
  setOverviewConsumptionError(error: ErrorResponse) {
    this.loadingOverviewConsumption = false;
    this.errorOverviewConsumption = error;
  }
  @Mutation
  setOverviewConsumptionSuccess(data: IOverviewConsumption) {
    this.loadingOverviewConsumption = false;
    this.dataOverviewConsumption = data;
  }
  @Action({ rawError: true })
  async fetchOverviewConsumption(FacilityId: string) {
    this.setLoadingOverviewConsumption(true);
    const servicesSiteAPI = new SiteAPI();
    const { error, data } = await servicesSiteAPI.getOverviewConsumption(
      FacilityId
    );
    if (error) {
      this.setOverviewConsumptionError(error);
    } else {
      this.setOverviewConsumptionSuccess(data);
    }
  }

  @Mutation
  setLoadingOverviewEnergyStorage(isLoading = false as boolean) {
    this.loadingOverviewEnergyStorage = isLoading;
  }
  @Mutation
  setOverviewEnergyStorageError(error: ErrorResponse) {
    this.loadingOverviewEnergyStorage = false;
    this.errorOverviewEnergyStorage = error;
  }
  @Mutation
  setOverviewEnergyStorageSuccess(data: IOverviewEnergyStorage) {
    this.loadingOverviewEnergyStorage = false;
    this.dataOverviewEnergyStorage = data;
  }
  @Action({ rawError: true })
  async fetchOverviewEnergyStorage(FacilityId: string) {
    this.setLoadingOverviewEnergyStorage(true);
    const servicesSiteAPI = new SiteAPI();
    const { error, data } = await servicesSiteAPI.getOverviewEnergyStorage(
      FacilityId
    );
    if (error) {
      this.setOverviewEnergyStorageError(error);
    } else {
      this.setOverviewEnergyStorageSuccess(data);
    }
  }
}
export default getModule(OverviewModule);
